@import url(https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Shadows+Into+Light+Two&display=swap);
/****Style-general****/
*{
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body{
  margin: 0;
  padding: 0;
}
h1{
  font-size : 2rem;
  font-family: 'EB Garamond', serif;
}
/* h2{
   font-family: 'EB Garamond', serif; 
} */
.img100{
  height : 100%;
  max-width : 30px;
  width :auto
}
img {
  width : 100%
}
button:hover{
  -webkit-transform: scale(1.09);
          transform: scale(1.09);
}

/***Navbar****/
.navbar{
  position: absolute;
  top : 0px;
  left : 10px;
  color : black;
  font-weight : 600;
  font-size : 1.3rem;
}
.active{
  -webkit-text-decoration: underline solid white 3px;
          text-decoration: underline solid white 3px;
  text-underline-offset: 3px;
  color : white!important
}

.link:link {
  text-decoration: none;
}
.nav-link:hover{
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  color : white
}
/***Fullpage***/
.full-page {
  margin : 0;
  min-height : 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top : 55px;
}
.full-center{
  display : flex;
  justify-content: center;
  align-items: center;
}


/***Home***/
.portrait{
  display : flex;
  justify-content: center;
}
.zone-img {
  object-fit: cover;
  height : 200px;
  width : 200px;
  border-radius : 180px;
  -webkit-filter: brightness(1.3);
          filter: brightness(1.3);
  background-image :linear-gradient(rgba(139, 207, 231, 0.164), rgba(86, 219, 236, 0.233)), url(/static/media/portrait.eca32c82.webp);
  background-size: cover;
  background-position: 0px
}  
.name{
  font-size: 2.5rem;
}  
.btn-light{
  -webkit-animation : appear-transition 6s;
          animation : appear-transition 6s 
}  
.section-button-link{
  -webkit-animation : appear-transition 7s;
          animation : appear-transition 7s 
}  

/*Animation-Home*/
.typewriter{
  overflow: hidden; 
  white-space: nowrap; 
  letter-spacing: .15em; 
  -webkit-animation: 
    typing 2.5s .7s steps(30, end),
    appear .7s; 
          animation: 
    typing 2.5s .7s steps(30, end),
    appear .7s;
  font-size : 1.2rem; 
}
.typewriter-city{
  overflow: hidden; 
  white-space: nowrap; 
  letter-spacing: .15em; 
  -webkit-animation: 
    appear-transition 4s; 
          animation: 
    appear-transition 4s;
  font-size : 1.6rem; 
  font-weight: 300;
}
.btn-link {
  height : 50px;
  width: 50px;
  border-radius : 90px;
  background-position: center;
  background-repeat: no-repeat;
  border:  none
}
.btn-link-github{
  background-image: url(/static/media/github.86997933.webp);
  background-size : contain;
  background-color: black;
}

.btn-link-linkedin{
  background-size : 50%;
  background-color: rgb(12, 101, 194);
  background-image: url(/static/media/linkedin.7692bf0f.webp);
}


/***Work***/
.details{
  justify-self: auto;
  font-size : 2rem;
  overflow-wrap:break-word;
}  


/***Projet***/
.wrapper-project{
  color:hsla(0,0%,100%,.8);
  position:relative;
  -webkit-perspective:600px;
  perspective:600px;
  height:360px;
  width:100%;
  max-width:400px;
}  
.wrapper-project:nth-child(1){
  -webkit-animation: appear-project 1s;
          animation: appear-project 1s;
}
.wrapper-project:nth-child(2){
  -webkit-animation: appear-project 2s;
          animation: appear-project 2s;
}
.wrapper-project:nth-child(3){
  -webkit-animation: appear-project 3s;
          animation: appear-project 3s;
}
.wrapper-project:nth-child(4){
  -webkit-animation: appear-project 4s;
          animation: appear-project 4s;
}
.wrapper-project:nth-child(5){
  -webkit-animation: appear-project 5s;
          animation: appear-project 5s;
}
.wrapper-project:nth-child(6){
  -webkit-animation: appear-project 6s;
          animation: appear-project 6s;
}
.wrapper-project:nth-child(7){
  -webkit-animation: appear-project 7s;
          animation: appear-project 7s;
}
.project h2{ 
  font-size : 1.8rem;
}
.project{
  background-color: rgba(165, 158, 158, 1);
  border-radius : 12px;
  height : 350px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width : 700px;
  outline:1px solid transparent;
  overflow:hidden;
  transform-style: preserve-3d;
  backface-visibility:hidden;
  -webkit-transform-style:preserve-3d;
  -webkit-backface-visibility:hidden;
}
.details {
  font-size: 1.2rem;
  border-radius : 12px;
  background-color: rgb(45, 43, 43);
  outline:1px solid transparent;
  overflow:hidden;
  backface-visibility:hidden;
  transform-style:preserve-3d;
  transform:rotatey(-180deg);
  -webkit-transform-style:preserve-3d;
  -webkit-backface-visibility:hidden;
  -webkit-transform:rotatey(-180deg)
} 
.wrapper-project > div{
  width:100%;
  height:370px;
  position:absolute;
  left:0;
  top:0;
  box-shadow:0 8px 5px hsla(0,0%,0%,.2);
  transition:.8s
}
.web{
  background-image: url(/static/media/website.f56e95ea.webp);
  background-size: contain!important;
  background-position: center;
  background-color: white;
}

.wrapper-project:hover .details{
  transform:rotatey(0);
  -webkit-transform:rotatey(0)
}
.wrapper-project:hover .project{
  -webkit-transform:rotatey(180deg);
  transform:rotatey(180deg)
}
.logo-language{
  height : 30px;
  margin-right : 7px;
  width : auto;
}

@media screen and (min-width : 500px){
  body h1{
    font-size : 3rem;
  }
  .wrapper-project{
    width : 100% 
  }
  .wrapper-project > div {
    padding: 20px;;
}
}

/**button*/
.button-link{
  background-size: cover;
  background-repeat: no-repeat; 
  border-radius: 12px;
  width : 100px; 
  height : 50px;
  border: none;
  font-weight :700;
}
.git{
  background-image: url(/static/media/github.86997933.webp);
}


/***CV***/
.cv{
  padding: 20px 0px;
}
.wrapper-infos{
  border-radius: 25px;
  background-size: cover;
  background-repeat: no-repeat;
  
}
.box-opacity{
  width: 100%;
  padding : 10px;
  border-radius: 25px;

}
.infos > div{
  padding : 14px;
  border-radius : 20px;
  display : flex;
  font-size : 1.3rem;
  font-weight : 600;
  font-family: 'Shadows Into Light Two', cursive;
}
.info:hover{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.wrapper-skills{
  padding : 20px;
  border-radius: 25px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 30Px;;
}
.infos .typewriter{
  font-size : 1.1rem;
  font-weight : 700;
  font-family: 'Shadows Into Light Two', cursive;
}
.infos{
  padding : 20px;
}
.wrapper-logo{
  display : flex;
  width : 40px;
  align-items: center;
  font-size : 1.2rem;
  font-weight : 600;
  font-family: 'Shadows Into Light Two', cursive;
}
/***skills***/
.progressbar-wrapper {
  background-color: #7d7f809f;
  color: white;
  border-radius: 10px;
  width: 100%;
  height : 23px;
  
}
.progressbar {
  color: white;
  
  text-align: right;
  border-radius: 10px;
  opacity : 0.7;
  height : 23px;
}
@media all and (min-width : 575px){
  .wrapper-logo{
    width : 140px;
  }
  .wrapper-skills{
    background-color: rgba(255, 255, 255, 0.313);
  }
  .box-opacity{
    background-color: rgba(255, 255, 255, 0.313);
  }
}



/*global media queries*/
@media screen and (min-width : 650px){
  .name{
    font-size : 4rem;
  }
  .typewriter{
    font-size : 2rem; 
  }
}
@media screen and (min-width : 2450px){
  html{
    font-size: 18px;
  }
  .wrapper-logo{
    font-size : 1rem;
  }
}


/***Animations**/
.typing-30{-webkit-animation : typing-30 4s ease-out;animation : typing-30 4s ease-out}
.typing-40{-webkit-animation : typing-40 4s ease-out;animation : typing-40 4s ease-out}
.typing-50{-webkit-animation : typing-50 4s ease-out;animation : typing-50 4s ease-out}
.typing-60{-webkit-animation : typing-60 4s ease-out;animation : typing-60 4s ease-out}
.typing-70{-webkit-animation : typing-70 4s ease-out;animation : typing-70 4s ease-out}
.typing-80{-webkit-animation : typing-80 4s ease-out;animation : typing-80 4s ease-out}

@-webkit-keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
@-webkit-keyframes typing-30 {
  from { width: 0 }
  to { width: 30% }
}
@keyframes typing-30 {
  from { width: 0 }
  to { width: 30% }
}
@-webkit-keyframes typing-40 {
  from { width: 0 }
  to { width: 40% }
}
@keyframes typing-40 {
  from { width: 0 }
  to { width: 40% }
}
@-webkit-keyframes typing-50 {
  from { width: 0 }
  to { width: 50% }
}
@keyframes typing-50 {
  from { width: 0 }
  to { width: 50% }
}
@-webkit-keyframes typing-60 {
  from { width: 0 }
  to { width: 60% }
}
@keyframes typing-60 {
  from { width: 0 }
  to { width: 60% }
}
@-webkit-keyframes typing-70 {
  from { width: 0 }
  to { width: 70% }
}
@keyframes typing-70 {
  from { width: 0 }
  to { width: 70% }
}
@-webkit-keyframes typing-80 {
  from { width: 0 }
  to { width: 80% }
}
@keyframes typing-80 {
  from { width: 0 }
  to { width: 80% }
}
@-webkit-keyframes appear{
  0% {opacity: 0}
  100% {opacity: 0}
}
@keyframes appear{
  0% {opacity: 0}
  100% {opacity: 0}
}
@-webkit-keyframes appear-transition{
  0% {opacity: 0}
  50% {opacity : 0}
  100% {opacity: 1}
}
@keyframes appear-transition{
  0% {opacity: 0}
  50% {opacity : 0}
  100% {opacity: 1}
}
@-webkit-keyframes appear-project{
  0% {opacity: 0}
  50% {opacity : 0}
  100% {opacity: 1}
}
@keyframes appear-project{
  0% {opacity: 0}
  50% {opacity : 0}
  100% {opacity: 1}
} 




